import { graphql, useStaticQuery } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React, { useMemo } from 'react';

import MDXComponents from 'src/components/mdx-components';
import { Section } from 'src/components/molecules/Section';

const query = graphql`
  query ProductReleaseData {
    allDatoCmsProductRelease {
      nodes {
        id
        productName
        versions
        initialReleaseDate
        endOfSoftwareMaintenance
        endOfTechnicalSupport
        extendedSupport
      }
    }
  }
`;

const dateOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  timeZone: 'UTC'
};

export default function DrawerProductReleaseTable({ heading, content, contentNode, customData }) {
  customData = JSON.parse(customData);

  const data = useStaticQuery(query);
  const productReleases = useMemo(() => {
    return data.allDatoCmsProductRelease.nodes
      .filter(node => {
        /** To indicate in DatoCMS */
        return node.productName === customData.productName;
      })
      .sort((a, b) => {
        /** For descending order */
        return new Date(b.initialReleaseDate) - new Date(a.initialReleaseDate);
      })
      .map((release, index) => {
        /** Format dates */
        const initialReleaseDate = new Date(release.initialReleaseDate);
        const endOfSoftwareMaintenance = new Date(release.endOfSoftwareMaintenance);
        const endOfTechnicalSupport = new Date(release.endOfTechnicalSupport);

        return {
          ...release,
          productName: index === 0 ? release.productName : '',
          releaseVersions: release.extendedSupport ? `${release.versions} (ES)` : release.versions,
          initialReleaseDate: initialReleaseDate.toLocaleDateString('en-US', dateOptions),
          endOfSoftwareMaintenance: endOfSoftwareMaintenance.toLocaleDateString('en-US', dateOptions),
          endOfTechnicalSupport: endOfTechnicalSupport.toLocaleDateString('en-US', dateOptions),
        }
      })
  }, [])

  return (
    <MDXProvider components={MDXComponents}>
      <Section {...customData?.flags}>
        {!!heading && <h4 id={heading}>{heading}</h4>}
        {content.length > 0 && <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>}
        {productReleases.length > 0 && (
          <table>
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Versions</th>
                <th>Initial Release Date</th>
                <th>End of Software Maintenance</th>
                <th>End of Technical Support</th>
              </tr>
            </thead>
            <tbody>
              {productReleases.map(release => {
                return (
                  <tr key={release.id}>
                    <td>{release.productName}</td>
                    <td>{release.versions}</td>
                    <td>{release.initialReleaseDate}</td>
                    <td>{release.endOfSoftwareMaintenance}</td>
                    <td>{release.endOfTechnicalSupport}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
      </Section>
    </MDXProvider>
  );
}
